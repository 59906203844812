import React from "react";
import { Map, GoogleApiWrapper , Marker} from 'google-maps-react' ;
import './localisationCss.css'

export function Localisation(props){
    return(

        <div className='mapClass'>
            <div className="mapClassMarge">
                <Map
                    google={props.google}
                    zoom={15}
                    containerStyle={{
                        position:"relative",
                        width: "100%",
                        height: "550px",
                        border: "5px solid black",
                    }}
                    style={mapStyles}
                    initialCenter={{lat:49.23441, lng:4.04808}}
                    center={{lat:49.23441, lng:4.04808}}
                >
                    <Marker name={'Casa Resto'}></Marker>
                </Map>
            </div>
        </div>

    );

}

const mapStyles = {
    width: '100%',
    height: '540px',
   
    

    
  };

  export default GoogleApiWrapper({
    apiKey: "AIzaSyAi9HN5gdb45cblR3xdbv-eO71P9hhzzuU"
})(Localisation);
  
