import React from "react";
//import { Row , Container, Col} from "react-bootstrap";
import InfoItem from "./InfoItem";
import './appInfoDivCss.css';
import modePaiementImage from '../../data/appData/appImages/modePaiement/modePaiement.jpeg'
import qualityImage from '../../data/appData/appImages/quality/quality.png'
import halalImage from '../../data/appData/appImages/halal/halal2.png'
import star5 from '../../data/appData/appImages/star/star5S.jpg'
//import star4 from '../../data/appData/appImages/star/star4S.jpg'
//import star2 from '../../data/appData/appImages/star/star2S.jpg'

function Info(){

    let modePaiementDescription = "Carte bancaire à partir de 15€" ;
    let qualityDescription ="Notre restaurant vous proposent des repas vriés, préparés soigneusement pour repondre à votre gôut et à vos attentes. Consommées sur place, emportées ou livrées, vos commandes sont traitées par une equipe de professionnels compétents.";
    let halalDescription="Tous nos produits sont certifiés Halal par les grandes associations de certification Halal";

    let evals = [
        [ star5, "Le 2021-04-10 18:52:00 Maëva", "C'est très bon, les prix sont très bien à recommander" ],
        [ star5, "Le 2021-01-22 22:22:55 Abdelali", "Restaurant agréable. Bons repas " ],
    ]
    return(
            <div>
                <InfoItem title="Evaluations" image={""}  description={""} isEvaluation={true} evals={evals}></InfoItem>
                <InfoItem title="Qualité" image={qualityImage}  description={qualityDescription} isEvaluation={false} evals={[[]]}></InfoItem>
                <InfoItem title="Halal" image={halalImage}  description={halalDescription} isEvaluation={false} evals={[[]]}></InfoItem>
                <InfoItem title="Modes de paiement" image={modePaiementImage}  description={modePaiementDescription} isEvaluation={false} evals={[[]]}></InfoItem>
                
            </div>
            


        
    );

}

export default Info