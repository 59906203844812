import React from "react";
import EvaluationItem from "./EvaluationItem";



function InfoItem(props){

    let image = ""
    if( props.image !== ""){
        image = <img  className="infoPhotoClass" src={props.image}  alt="kebab reims" /> ;
    }

    

    return(
        <div className='infoDiv'>
            <div id='infoDiv1_1' className='infoDivSous infoDivSousResp'>
                    <div className='infoTitle' id='infoTitle1' > <br/>{props.title} <br/><br/></div>
                        <div id='evaluationDivScrollId'>
                            <div className='infoDivTextDiv'>
                                
                                {image}

                               <p className='parInfoClass'>{props.description} </p>
                                
                                

                                {props.evals.map((item, index) => {
                                    return(
                                        <EvaluationItem 
                                            image={item[0]}
                                            dateName={item[1]}
                                            eval={item[2]}
                                            key={index}
                                        />
                                    );
                                })}
                                
                            </div>
                        </div>
                    
                    
                </div>
        </div>
    );
}

export default InfoItem