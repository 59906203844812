import React  from 'react';
import logo from '../../data/appData/appImages/entete/logo2.png'
import logo2 from '../../data/appData/appImages/entete/couverture.png'
import './appHeaderCss.css'
//import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
//import { faMapMarker } from '@fortawesome/fontawesome-free-solid';

function Header(){

    return(





        <div>
            <div  className=" bandeauClass bandeauClassResp">

                <div id='logoDiv' className="logoDivClassResp">

                    
                    <img className='logoImage1'   src={logo} alt='casa resto logo' />

    
                </div>

                <div className="logoDiv2 logoDiv2ClassResp">

                    <img className='logoImage2'   src={logo2} alt='restaurant casa resto' />
                

                </div>


                
            </div>
            
           

        </div>



    );
}
export default Header