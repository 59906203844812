import React from "react";
import CommandLink from '../commandLink/CommandLink'
import MenuItem from '../menuItem/MenuItem'
import './appWelcomeCss.css' ;
import formuleLogo from '../../data/appData/appImages/carteCatalogue/230px/formule.png'
import pizzaTLogo from '../../data/appData/appImages/carteCatalogue/230px/pizza_t.png'
import pizzaCLogo from '../../data/appData/appImages/carteCatalogue/230px/pizza_cf.png'
import assietteLogo from '../../data/appData/appImages/carteCatalogue/230px/assiette.png'
import tacosLogo from '../../data/appData/appImages/carteCatalogue/230px/tacos2.png'
import sandwichLogo from '../../data/appData/appImages/carteCatalogue/230px/sandwich.png'
import burgerLogo from '../../data/appData/appImages/carteCatalogue/230px/burger.png'
import texMexLogo from '../../data/appData/appImages/carteCatalogue/230px/texMex.png'
import saladeLogo from '../../data/appData/appImages/carteCatalogue/230px/salade.png'
import dessertLogo from '../../data/appData/appImages/carteCatalogue/230px/dessert.png'
import boissonLogo from '../../data/appData/appImages/carteCatalogue/230px/boisson.png'
import enfantLogo from '../../data/appData/appImages/carteCatalogue/230px/enfant.png'


function Content(){
    const CATEGORY_URL ="https://www.walima-eat.com/#/etablissements/fr/51100/8/site/";


    const menuItemTable =[
        [sandwichLogo, CATEGORY_URL + "category1", "NOS SANDWICHS"],
        [pizzaTLogo, CATEGORY_URL + "category1", "NOS PIZZAS T"],
        [pizzaCLogo, CATEGORY_URL + "category1", "NOS PIZZAS C F"],
        [assietteLogo, CATEGORY_URL + "category4", "NOS PLATS"],
        [tacosLogo, CATEGORY_URL + "category5", "NOS TACOS"],
        [burgerLogo, CATEGORY_URL + "category7", "NOS BURGERS"],
        [texMexLogo, CATEGORY_URL + "category8", "NOS TEXMEX"],
        [enfantLogo, CATEGORY_URL + "category10","NOS MENUS ENFANT"],
        [saladeLogo, CATEGORY_URL + "category9","NOS SALADES"],
        [dessertLogo, CATEGORY_URL + "category11","NOS DESSERTS"],
        [boissonLogo, CATEGORY_URL + "category12","NOS BOISSONS"],
    ];

    /* const menuItemTable =[
        [formuleLogo, "https://www.casa-resto.fr", "NOS FORMULES"],
        [pizzaTLogo, "https://www.casa-resto.fr", "NOS PIZZAS T"],
        [pizzaCLogo, "https://www.casa-resto.fr", "NOS PIZZAS C F"],
        [assietteLogo, "https://www.casa-resto.fr", "NOS PLATS"],
        [tacosLogo, 'https://www.casa-resto.fr', "NOS TACOS"],
        [sandwichLogo, 'https://www.casa-resto.fr', "NOS SANDWICHS"],
        [burgerLogo, 'https://www.casa-resto.fr', "NOS BURGERS"],
        [paniniLogo, 'https://www.casa-resto.fr', "NOS PANINIS"],
        [texMexLogo, 'https://www.casa-resto.fr', "NOS TEXMEX"],
        [enfantLogo, 'https://www.casa-resto.fr',"NOS MENUS ENFANT"],
        [saladeLogo, 'https://www.casa-resto.fr',"NOS SALADES"],
        [dessertLogo, 'https://www.casa-resto.fr',"NOS DESSERTS"],
        [boissonLogo, 'https://www.casa-resto.fr',"NOS BOISSONS"],
    ]; */


    return(

        <div className='content1Div'>
            <CommandLink></CommandLink>

            <div className="centePerCentWidthAndFloatContainer">
                <div className='rowContainer rowContainerResp'>
                    
                    <div>
                        {menuItemTable.map((item, index) => {
                                return (
                                    <MenuItem menuItemLogo={item[0]}  menuItemLink={item[1]} itemTitle={item[2]} key={index}></MenuItem>
                                );

                            })}
                    </div>
                
                </div>
            </div>
            

        </div>

    );
}

export default Content