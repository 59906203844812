//import logo from './logo.svg';
import './App.css';
import React  from 'react';
import Header from './view/header/Header';
import Info from './view/info/Info';
import Footer from './view/footer/Footer'
import './data/appData/appCss/appGeneralCss.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import Menu from './view/menu/menu';
//import Localisation  from './view/localisation/Localisation';

function App() {

/*
<div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  */

  return (
    <div className="App">
      <Header></Header>
      
      <Menu></Menu>
      <br/><br/>
      <Info></Info>
      <br/><br/>
      {
      //<Localisation/>
    }
      <br/><br/>
      <Footer></Footer>
    </div>
  );
}

export default App;
