
import React from "react";

function MenuItem(props){

    return(

        <div>

            <a href={props.menuItemLink} >
                    <div className='cartDiv cartDivResp'>
                    <div className="carteLabelClass">{props.itemTitle}</div>
                        <div className="carteDivMarge">
                            <img className="carteImageClass"  src={props.menuItemLogo} alt={"casa resto " + props.itemTitle} />
                        </div>
                        
                    </div>
            </a>
        </div>

    );

}

export default MenuItem